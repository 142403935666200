<template>
    <div class="helper" style="height: 100%;">
        <Top name="账号助手" email></Top>
        <van-tabs v-model="active" >
            <van-tab title="物品找回" to="/helper"></van-tab>
            <van-tab title="账号申诉" to="/helper/appeal"></van-tab>
            <van-tab title="删二级密码" to="/helper/remowepassword"></van-tab>
        </van-tabs>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 84px);overflow-y: auto;">
            <router-view v-if="isRouterAlive"/>
        </van-pull-refresh>
        
        <!-- <van-form class="sale_form" @submit="onSubmit">
            <van-row type="flex" justify="center">
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.game"
                        label="选择游戏"
                        placeholder="请选择"
                        :left-icon="form.gameicon"
                        @click="form.gamePicker = true"
                    />
                    <van-popup v-model="form.gamePicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="form.games"
                            @confirm="popConfirm"
                            @cancel="form.gamePicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.server"
                        label="选择区服"
                        placeholder="请选择"
                        :left-icon="form.servericon"
                        @click="form.serverPicker = true"
                    />
                    <van-popup v-model="form.serverPicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="form.servers"
                            @confirm="popConfirm"
                            @cancel="form.serverPicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21">
                    <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="form.role"
                        label="选择角色"
                        placeholder="请选择"
                        :left-icon="form.roleicon"
                        @click="form.rolePicker = true"
                    />
                    <van-popup v-model="form.rolePicker" position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="form.roles"
                            @confirm="popConfirm"
                            @cancel="form.rolePicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="21" class="empower">
                    <van-field
                        name="checkbox"
                        label="授权查看角色属性及携带物品"
                        :left-icon="form.checkicon"
                    >
                        <template #input>
                            <van-checkbox v-model="form.empowerCheck" shape="round">勾选授权</van-checkbox>
                        </template>
                    </van-field>
                </van-col>
            </van-row>
            <van-row type="flex" justify="center" class="van_rule">
                <van-col span="21" class="sumbit_btn">
                    <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">申请上架</van-button>
                </van-col>
            </van-row>
        </van-form> -->
    </div>
</template>
<script>
import Top from '@/components/top'

export default {
    name: 'Helper',
    components: {
        Top
    },
    data() {
        return {
            isLoading:false,
            active: 0,
            isRouterAlive:true
        }
    },
    methods: {
        onRefresh(){
            // console.log(222)
            this.isRouterAlive = false
            this.$nextTick(function () {
                
                this.isRouterAlive = true
            })
            setTimeout(()=>{
                this.isLoading = false
            },500)
            
            // this.isLoading = false
        },
        //tab切换
        tabsClick(name) {
            //console.log(name);
        }
    },
    watch: {
        $route: {
            immediate: true, // 一旦监听到路由的变化立即执行
            handler(to, from) {
                // console.log(to.name);
                switch(to.name) {
                    case 'trackit':
                        this.active = 0;
                        break;
                    case 'Appeal':
                        this.active = 1;
                        break;
                    case 'Remowepassword':
                        this.active = 2;
                        break;
                } 
            },
        },
    }
}
</script>
<style>
.helper .van-tabs__wrap .van-tabs__line{
    background-color: #FF4E00;
}
.helper .van-tabs__wrap .van-tab--active{
    font-weight: 700;
    color: #FF4E00;
}
</style>